import { ProduitType } from '@innedit/innedit';
import { DataProps, useSelector } from 'dataformjs';
import moment from 'moment';
import React, { FC } from 'react';

interface InfosProps extends DataProps {
  formName: string;
}
const Infos: FC<InfosProps> = ({ formName }) => {
  const produit = useSelector(
    (globalState: any) => globalState.form[formName].values as ProduitType,
  );

  let diffJours;
  let newSoldoutAt;

  const { isDraft, soldoutAt, publishedAt } = produit;
  const { onSale } = produit;

  if (!onSale) {
    if (soldoutAt) {
      newSoldoutAt = moment(soldoutAt);
      diffJours = newSoldoutAt.diff(moment(publishedAt), 'days');
    }
  } else {
    diffJours = moment().diff(moment(publishedAt), 'days');
  }

  if (isDraft) {
    return <div style={{ padding: '1.5rem' }}>Brouillon</div>;
  }

  return (
    <div className="p-3">
      {!onSale ? (
        <span>
          Ce produit a été vendu{' '}
          {newSoldoutAt &&
            `le ${newSoldoutAt.format('DD/MM/YY')} en ${diffJours} jours`}
        </span>
      ) : (
        <span>Ce produit est en vente depuis {diffJours} jours</span>
      )}
    </div>
  );
};

export default Infos;
