import {
  CommandeData,
  CommandeProduitType,
  FirebaseFirestore,
  MarchandData,
} from '@innedit/innedit';
import { change, Group, useDispatch, WrappedFieldArrayProps } from 'dataformjs';
import keyBy from 'lodash/keyBy';
import fetch from 'node-fetch';
import React, { SyntheticEvent, useEffect, useState, VFC } from 'react';

import Button from '../../../../../../../components/Button';
import { ProduitsProps } from './index';
import Produit from './Item';

const ProduitsRender: VFC<WrappedFieldArrayProps & ProduitsProps> = ({
  boutique,
  fields,
  docId,
  formName,
  formValues,
}) => {
  const dispatch = useDispatch();
  const [produitsLUT, setProduitsLUT] = useState<{
    [id: string]: FirebaseFirestore.QueryDocumentSnapshot<CommandeProduitType>;
  }>({});
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [marchands, setMarchands] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();

  const {
    deliveryCost,
    deliveryIsRefunded,
    deliveryMethod,
    paymentStatus,
    produits,
    status,
  } = formValues;

  useEffect(() => {
    const marchandModel = new MarchandData({ boutique });
    const unsub = marchandModel.watch(querySnapshot => {
      setMarchands(querySnapshot.docs);
    });

    const commandeModel = new CommandeData({ boutique });
    commandeModel
      .findProduitsByIds(produits.map((p: { id: string }) => p.id))
      .then(docs => setProduitsLUT(keyBy(docs, 'id')))
      .catch(error => console.error(error.message));

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, produits]);

  const isCaptured =
    produits && produits.some((p: CommandeProduitType) => p.confirmedAt);

  const handleSelectedOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const index = event.currentTarget.getAttribute('data-index');
    if (index) {
      const newSelectedProducts = [...selectedProducts];
      const findIndex = selectedProducts.findIndex(
        p => p === parseInt(index, 10),
      );
      if (findIndex >= 0) {
        newSelectedProducts.splice(findIndex, 1);
      } else {
        newSelectedProducts.push(parseInt(index, 10));
      }
      setSelectedProducts(newSelectedProducts);
    }
  };

  const handleCaptureOnClick = async () => {
    const lignes: CommandeProduitType[] = [];
    const amountProducts = selectedProducts.reduce((acc, spIndex) => {
      const tmpProduit = fields.get(spIndex);

      lignes.push({
        ...produitsLUT[tmpProduit.id].data(),
        id: tmpProduit.id,
        qty: tmpProduit.qty,
      });

      let subTotal = 0;
      if (tmpProduit.price) {
        subTotal = tmpProduit.qty * tmpProduit.price;
      }

      const total = subTotal;

      return acc + total;
    }, 0);

    const amountDelivery = 'carrier' === deliveryMethod ? deliveryCost : 0;

    const amount = amountProducts + amountDelivery;

    const ok = window.confirm(
      `Vous êtes sur le point de confirmer cette commande pour un montant total de ${amount}€ ?`,
    );

    if (ok) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${formValues.boutique}`;
      url += `/payment_intents/capture?commande=${docId}&amountProduits=${amountProducts}&produits=${JSON.stringify(
        selectedProducts,
      )}&amountDelivery=${amountDelivery}`;

      return fetch(url)
        .then(res => res.json())
        .then(updateFieldscommande => {
          if (updateFieldscommande.error) {
            alert(
              "La commande n'a pas pu être confirmée, veuillez contacter l'administrateur du site",
            );

            return dispatch(
              change(
                formName,
                `errors[${
                  (formValues &&
                    formValues.errors &&
                    formValues.errors.length) ||
                  0
                }]`,
                updateFieldscommande,
              ),
            );
          }
          alert('Commande confirmée');
          setSelectedProducts([]);
          console.info('updateFieldscommande', updateFieldscommande);

          return Object.keys(updateFieldscommande).map(field =>
            dispatch(change(formName, field, updateFieldscommande[field])),
          );
        });
    }

    return null;
  };

  const handleRefundOnClick = () => {
    const amountProducts = selectedProducts.reduce((acc, spIndex) => {
      const tmpProduit = fields.get(spIndex);

      let subTotal = 0;
      if (tmpProduit.price) {
        subTotal = tmpProduit.qty * tmpProduit.price;
      }

      const total = subTotal;

      return acc + total;
    }, 0);

    const ok = window.confirm(
      `Vous êtes sur le point de rembourser cette commande pour un montant de ${amountProducts}€ ?`,
    );

    if (ok) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${formValues.boutique}`;
      url += `/refunds/create?commande=${docId}&amount=${amountProducts}&produits=${JSON.stringify(
        selectedProducts,
      )}`;

      return fetch(url)
        .then(res => res.json())
        .then(updateFieldscommande => {
          if (updateFieldscommande.error) {
            alert(
              "La commande n'a pas pu être remboursée, veuillez contacter l'administrateur du site",
            );

            return dispatch(
              change(
                formName,
                `errors[${
                  (formValues &&
                    formValues.errors &&
                    formValues.errors.length) ||
                  0
                }]`,
                updateFieldscommande,
              ),
            );
          }

          alert('Commande remboursée');
          setSelectedProducts([]);

          return Object.keys(updateFieldscommande).map(field =>
            dispatch(change(formName, field, updateFieldscommande[field])),
          );
        });
    }

    return null;
  };

  const handleRefundDeliveryOnClick = () => {
    const ok = window.confirm(
      `Vous êtes sur le point de rembourser la livraison cette commande pour un montant de ${deliveryCost}€ ?`,
    );

    if (ok) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${formValues.boutique}`;
      url += `/refunds/create?commande=${docId}&amount=${deliveryCost}&delivery=true`;

      return fetch(url)
        .then(res => res.json())
        .then(updateFieldscommande => {
          if (updateFieldscommande.error) {
            alert(
              "La livraison n'a pas pu être remboursée, veuillez contacter l'administrateur du site",
            );

            return dispatch(
              change(
                formName,
                `errors[${
                  (formValues &&
                    formValues.errors &&
                    formValues.errors.length) ||
                  0
                }]`,
                updateFieldscommande,
              ),
            );
          }

          alert('Livraison remboursée');

          return Object.keys(updateFieldscommande).map(field =>
            dispatch(change(formName, field, updateFieldscommande[field])),
          );
        });
    }

    return null;
  };

  const handleTransferOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const index = event.currentTarget.getAttribute('data-index');
    console.info('transfert produit ', index);

    const ok = window.confirm(
      `Vous êtes sur le point de transférer le paiement pour le produit ${index} ?`,
    );

    if (ok) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${formValues.boutique}`;
      url += `/transfers/create?commande=${docId}&produit=${index}`;

      return fetch(url)
        .then(res => res.json())
        .then(updateFieldscommande => {
          if (updateFieldscommande.error) {
            alert(
              "Le paiement n'a pas pu être transféré, veuillez contacter l'administrateur du site",
            );

            const errorIndex =
              (formValues && formValues.errors && formValues.errors.length) ||
              0;

            return dispatch(
              change(formName, `errors[${errorIndex}]`, updateFieldscommande),
            );
          }
          alert('Paiement transféré');
          console.info('updateFieldscommande', updateFieldscommande);

          return Object.keys(updateFieldscommande).map(field =>
            dispatch(change(formName, field, updateFieldscommande[field])),
          );
        });
    }

    return null;
  };

  const handleReversalTransferOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const index = event.currentTarget.getAttribute('data-index');
    console.info('reserval transfert produit ', index);

    const ok = window.confirm(
      `Vous êtes sur le point d'annuler le transfert du paiement pour le produit ${index} ?`,
    );

    if (ok) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${formValues.boutique}`;
      url += `/transfers/create-reversal?commande=${docId}&produit=${index}`;

      return fetch(url)
        .then(res => res.json())
        .then(updateFieldscommande => {
          if (updateFieldscommande.error) {
            alert(
              "Le transfert n'a pas pu être annulé, veuillez contacter l'administrateur du site",
            );

            return dispatch(
              change(
                formName,
                `errors[${
                  (formValues &&
                    formValues.errors &&
                    formValues.errors.length) ||
                  0
                }]`,
                updateFieldscommande,
              ),
            );
          }
          alert('Transfert annulé');
          console.info('updateFieldscommande', updateFieldscommande);

          return Object.keys(updateFieldscommande).map(field =>
            dispatch(change(formName, field, updateFieldscommande[field])),
          );
        });
    }

    return null;
  };

  console.info('status', status);

  return (
    <Group title="Produits">
      {fields.length > 0 ? (
        <>
          {'requires_capture' === formValues.paymentStatus && (
            <div className="text-center text-primary-500 mb-6">
              En attente de confirmation
            </div>
          )}
          <ul>
            {fields.map((name, fieldIndex, allfields) => (
              <Produit
                key={name}
                boutique={boutique}
                checked={selectedProducts.findIndex(p => p === fieldIndex) >= 0}
                formName={formName}
                index={fieldIndex}
                isCapturable={'succeeded' === status}
                marchands={marchands}
                name={name}
                produits={allfields}
                reversalTransferOnClick={handleReversalTransferOnClick}
                selectedOnChange={handleSelectedOnChange}
                showCheckbox={'succeeded' === paymentStatus || !isCaptured}
                transferOnClick={handleTransferOnClick}
              />
            ))}
          </ul>
          <div className="flex space-x-1">
            {selectedProducts && selectedProducts.length > 0 && !isCaptured && (
              <Button
                onClick={handleCaptureOnClick}
                size="sm"
                text="Confirmer"
              />
            )}

            {selectedProducts && selectedProducts.length > 0 && isCaptured && (
              <Button
                onClick={handleRefundOnClick}
                size="sm"
                status="secondary"
                text={`Rembourser ${
                  selectedProducts.length > 1 ? 'les produits' : 'le produit'
                }`}
              />
            )}

            {'carrier' === deliveryMethod &&
              !deliveryIsRefunded &&
              isCaptured && (
                <Button
                  onClick={handleRefundDeliveryOnClick}
                  size="sm"
                  status="secondary"
                  text="Rembourser la livraison"
                />
              )}
          </div>
        </>
      ) : (
        <p>Aucun produit</p>
      )}
    </Group>
  );
};

export default ProduitsRender;
