import { FirebaseFirestore } from '@innedit/innedit';
import { Data, DataProps } from 'dataformjs';
import React, { FC, memo, useEffect, useState } from 'react';

interface DataPricingProps extends DataProps {
  boutique: FirebaseFirestore.DocumentSnapshot;
  datas: DataProps[];
  formName: string;
  name: string;
}

const DataPricing: FC<DataPricingProps> = ({
  boutique,
  datas,
  formName,
  name,
}) => {
  const [prices, setPrices] = useState<FirebaseFirestore.DocumentSnapshot[]>();

  useEffect(() => {
    if (boutique) {
      boutique.ref
        .collection('prices')
        .where('isDefault', '==', false)
        .where('deleted', '==', false)
        .orderBy('datetime', 'desc')
        .get()
        .then(querySnapshot => setPrices(querySnapshot.docs))
        .catch(error => console.error(error.message));
    }
  }, [boutique]);

  if (!prices || 0 === prices.length) {
    return null;
  }

  return (
    <>
      {prices.map(price => (
        <Data
          key={price.id}
          componentType="group"
          datas={[
            {
              datas,
              formName,
              componentType: 'section',
              name: `${name}.${price.id}`,
            },
          ]}
          formName={formName}
          title={price.get('libelle')}
        />
      ))}
    </>
  );
};

export default memo(DataPricing);
