import {
  change,
  DataField,
  DataProps,
  useDispatch,
  useSelector,
} from 'dataformjs';
import React, { FC, useEffect, useRef } from 'react';

const usePreviousHasInventory = (value: boolean) => {
  const ref = useRef<boolean>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

interface InventaireProps extends DataProps {
  formName: string;
  user?: firebase.default.firestore.DocumentSnapshot;
}

const Inventaire: FC<InventaireProps> = ({ formName, user }) => {
  const { hasInventory, productType = 'classic' } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );
  const dispatch = useDispatch();
  const previousHasInventory = usePreviousHasInventory(hasInventory || false);

  useEffect(() => {
    if (
      undefined !== previousHasInventory &&
      previousHasInventory !== hasInventory
    ) {
      if (hasInventory) {
        dispatch(change(formName, 'qtyAvailable', 1));
        dispatch(change(formName, 'soldoutAt', ''));
      } else {
        dispatch(change(formName, 'qtyAvailable', 0));
      }
    }
  }, [dispatch, formName, hasInventory, previousHasInventory]);

  return (
    <DataField
      className={'classic' === productType ? 'no-margin-bottom' : ''}
      componentType="input"
      description="Tenir un inventaire ?"
      label="Politique d'inventaire"
      name="hasInventory"
      type="checkbox"
    />
  );
};

export default Inventaire;
