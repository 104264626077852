import {
  FirebaseFirestore,
  getFirestore,
  UrlData,
  UrlType,
} from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../../../../components/Button';
import IconDelete from '../../../../../icons/Delete';
import HOCGroup from '../../../../CMS/components/HOC/Group';

const Pathnames: FC<{
  boutique: FirebaseFirestore.DocumentSnapshot;
  className?: string;
  docId: string;
  formName: string;
}> = ({ boutique, className, docId, formName }) => {
  const [urls, setUrls] =
    useState<FirebaseFirestore.QueryDocumentSnapshot<UrlType>[]>();
  const { pathname } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );

  useEffect(() => {
    const urlData = new UrlData({ boutique });

    urlData.watch(querySnapshot => setUrls(querySnapshot.docs), {
      wheres: {
        docId,
      },
    });
  }, [boutique, docId]);

  const handleDeleteUrl = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const urlData = new UrlData({ boutique });

    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      urls &&
      Number.isInteger(i) &&
      window.confirm('Voulez-vous vraiment supprimer cette adresse ?')
    ) {
      urlData.delete(urls[i]);
    }
  };

  const handleSetDefaultUrl = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (urls && Number.isInteger(i) && urls[i]) {
      const docRef = urls[i].get('docRef')
        ? urls[i].get('docRef')
        : getFirestore(boutique)
            .collection(`${urls[i].get('type')}s`)
            .doc(urls[i].get('docId'));

      (docRef as firebase.default.firestore.DocumentReference)
        .update({
          pathname: urls[i].get('pathname'),
        })
        .catch(error => {
          throw new Error(
            `handleSetDefaultUrl : docRef.update : ${error.message}`,
          );
        });
    } else {
      console.error("L'url n'existe pas pour l'index", i);
    }
  };

  if (!urls) {
    return null;
  }

  return (
    <HOCGroup title="Urls associées">
      <ul className="flex space-y-3 flex-col">
        {urls.map((url, idx) => (
          <li key={url.id} className="flex justify-between">
            <span className="libelle">{url.get('pathname')}</span>
            <span className="flex space-x-1 items-center">
              {pathname !== url.get('pathname') && (
                <Button
                  data-index={idx}
                  onClick={handleSetDefaultUrl}
                  size="sm"
                  status="neutral"
                  text="Choisir comme url"
                />
              )}

              {urls.length > 1 && (
                <Button
                  data-index={idx}
                  iconLeft={IconDelete}
                  onClick={handleDeleteUrl}
                  size="sm"
                  status="danger"
                />
              )}
            </span>
          </li>
        ))}
      </ul>
    </HOCGroup>
  );
};

export default Pathnames;
