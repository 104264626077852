import { ActionData, FirebaseFirestore, MediaData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useRef, useState } from 'react';

import svg from '../../../../images/dropzone.svg';
import HOCGroup from '../HOC/Group';
import Modal from '../View/Modal';
import Media from './Item';
import MediasList from './List';
import { List as ListSC } from './styles';

interface MediasProps {
  accept?: string;
  addText: string;
  boutique?: FirebaseFirestore.DocumentSnapshot;
  addItems: (medias: FirebaseFirestore.DocumentSnapshot[]) => void;
  changePosition: (oldIndex: number, newIndex: number) => void;
  removeItem: (index: number) => void;
  items: string[];
  title: string;
}

const Medias: FC<MediasProps> = ({
  accept = 'image/jpeg',
  addItems,
  addText,
  boutique,
  changePosition,
  items,
  removeItem,
  title,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<string[]>();
  const [isOpenMedias, setIsOpenMedias] = useState<boolean>();
  const mediaData = new MediaData({ boutique });

  const onDrop = (files: FileList) => {
    const promises: any[] = [];
    const tmpPreviews = [];
    for (let i = 0; i < files.length; i += 1) {
      const media = files.item(i);
      if (media) {
        console.info('media', media);
        const preview = URL.createObjectURL(media);
        tmpPreviews.push(preview);

        promises.push(mediaData.uploadFile(media));
      }
    }
    setPreviews(tmpPreviews);

    Promise.all(promises)
      .then(values => {
        const tmpItems: FirebaseFirestore.DocumentSnapshot[] = [];
        values.forEach(document => {
          if (document.exists) {
            tmpItems.push(document);
          } else {
            console.error("Le document n'existe pas");
          }
        });

        addItems(tmpItems);

        return setPreviews([]);
      })
      .catch(error => {
        // TODO afficher le message d'erreur -> Mettre en place un système de notification
        console.error(error.code, error.message);

        return setPreviews([]);
      });
  };

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.currentTarget && e.currentTarget.files) {
      onDrop(e.currentTarget.files);
    }
  };

  const handleOpenBrowser = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSelectMedias = () => {
    setIsOpenMedias(!isOpenMedias);
  };

  /**
   * Cette methode permet de supprimer un media du tableau
   *
   * @param {number} index
   */
  const handleRemoveItem = (index: number) => {
    if (items[index]) {
      if (items[index]) {
        ActionData.create(
          'media-delete',
          {
            id: items[index],
          },
          {
            boutique,
          },
        );
      }

      removeItem(index);
    }
  };

  return (
    <HOCGroup addOnClick={handleOpenBrowser} addText={addText} title={title}>
      {isOpenMedias && (
        <Modal
          closeOnClick={handleSelectMedias}
          title="Selectionner des médias"
        >
          <MediasList addItems={addItems} boutique={boutique} />
        </Modal>
      )}

      <div className="group">
        {previews && previews.length > 0 && (
          <span className="loading-in-progress">Chargement en cours</span>
        )}
        <input
          ref={inputRef}
          accept={accept}
          className="hidden"
          multiple
          onChange={handleOnChange}
          type="file"
        />
        {(!items || 0 === items.length) &&
          (!previews || 0 === previews.length) && (
            <div className="flex items-center justify-center">
              <img alt="Upload files" src={svg} width={200} />
            </div>
          )}
        {((items && items.length > 0) || (previews && previews.length > 0)) && (
          <div className="w-full outline-none box-border">
            <ListSC>
              {items.map((mediaId, index) => (
                <Media
                  key={mediaId}
                  boutique={boutique}
                  changePosition={changePosition}
                  index={index}
                  mediaId={mediaId}
                  removeItem={handleRemoveItem}
                />
              ))}
              {previews &&
                previews.length > 0 &&
                previews.map(media => (
                  <li key={media}>
                    <figure className="aspect-ratio aspect-ratio--square">
                      <img
                        alt="chargement en cours"
                        className="aspect-ratio__content"
                        src={media}
                        style={{ filter: 'grayscale(1)' }}
                      />
                    </figure>
                  </li>
                ))}
            </ListSC>
          </div>
        )}
      </div>
    </HOCGroup>
  );
};

export default Medias;
