import { BoutiqueData, FirebaseFirestore } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';

import nextPhoto from '../../../../../../images/next-photo.svg';
import StyledCard from '../../styles/Card';

const Item: FC<{
  boutique?: FirebaseFirestore.DocumentSnapshot;
  doc: FirebaseFirestore.DocumentSnapshot;
  isSelected?: boolean;
  nbParLigne?: number;
  onClick?: (e: SyntheticEvent<HTMLLIElement>) => void;
}> = ({ boutique, doc, isSelected = false, nbParLigne = 4, onClick }) => {
  let photo;
  try {
    if (doc.get('fullPath')) {
      photo = `${BoutiqueData.getDomainImagesURL(boutique)}/${doc.get(
        'fullPath',
      )}?w=320&h=320&c=max`;
    } else {
      photo = nextPhoto;
    }
  } catch (e) {
    photo = nextPhoto;
  }

  const width = nbParLigne ? 100 / nbParLigne : 20;

  return (
    <li
      data-id={doc.id}
      onClick={onClick}
      role="presentation"
      style={{
        boxSizing: 'border-box',
        display: 'block',
        padding: '1.5rem 0 0 1.5rem',
        width: `${width}%`,
      }}
    >
      <StyledCard
        className={isSelected ? 'isSelected' : ''}
        isSelected={isSelected}
      >
        <figure className="card-image aspect-ratio aspect-ratio--square">
          <img alt="Chargement" className="aspect-ratio__content" src={photo} />
        </figure>
        <h3 className="name">{doc.get('name')}</h3>
      </StyledCard>
    </li>
  );
};

export default Item;
