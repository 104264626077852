import { FieldArray } from 'dataformjs';
import React, { FC } from 'react';

import DataMediasRender from './Render';

const DataMedias: FC<any> = props => (
  <FieldArray {...props} component={DataMediasRender} />
);

export default DataMedias;
