import {
  BoutiqueType,
  FirebaseFirestore,
  getFirestore,
} from '@innedit/innedit';
import { Dispatch } from 'dataformjs';

import {
  BOUTIQUE_CHANGE,
  BOUTIQUE_UPDATE,
  BOUTIQUES_UNSUBSCRIBE,
  BOUTIQUES_WATCH,
  LIST_COLLECTION_SET,
  LIST_DOCS_SET,
  LIST_SEARCH_SET,
  LIST_TAB_SET,
} from '../../actionTypes';
import { ListTabProps } from './components/new/props';

export const changeIndex = (index: number): any => ({
  index,
  type: BOUTIQUE_CHANGE,
});

export const setListDocs = (
  docs?: FirebaseFirestore.QueryDocumentSnapshot[],
): any => ({
  docs,
  type: LIST_DOCS_SET,
});

export const setListSearch = (q?: string): any => ({
  q,
  type: LIST_SEARCH_SET,
});

export const setListTab = (tab: ListTabProps): any => ({
  tab,
  type: LIST_TAB_SET,
});

export const setListCollection = (collection: string): any => ({
  collection,
  type: LIST_COLLECTION_SET,
});

export const update = (
  ref: FirebaseFirestore.DocumentReference,
  item: Partial<BoutiqueType>,
): any => ({
  payload: ref.update({ ...item }),
  type: BOUTIQUE_UPDATE,
});

export function watch(user: FirebaseFirestore.DocumentSnapshot) {
  return (dispatch: Dispatch): any => {
    const unsubscribe = getFirestore()
      .collection('boutiques')
      .where('deleted', '==', false)
      .orderBy('name')
      .onSnapshot(querySnapshot => {
        const docs =
          'admin' === user.get('type')
            ? querySnapshot.docs
            : querySnapshot.docs.filter(doc =>
                user.get('boutiques').includes(doc.id),
              );

        let indexBoutique = 0;
        if (user.get('boutique')) {
          // On met à jour l'index de la boutique par défaut
          indexBoutique = docs.findIndex(
            boutique => boutique.id === user.get('boutique'),
          );
        }

        return dispatch({
          indexBoutique,
          boutiques: docs,
          type: BOUTIQUES_WATCH,
        });
      });

    return dispatch({ unsubscribe, type: BOUTIQUES_UNSUBSCRIBE });
  };
}
