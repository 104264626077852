import 'moment/locale/fr';
import 'font-awesome/css/font-awesome.min.css';
import './src/core/styles/base.css';

import { firebase } from '@innedit/innedit';
import { InneditProvider } from '@innedit/innedit-react';
import { FormidableProvider } from 'dataformjs';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Translation as TranslationI18N } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import Button from './src/core/components/Button';
import {
  FieldMessage,
  FieldTemplate,
  FieldWrapper,
} from './src/core/components/Field';
import Group, {
  GroupBody,
  GroupDescription,
  GroupTitle,
} from './src/core/components/Group';
import Input, { InputGroup } from './src/core/components/Input';
import Select from './src/core/components/Select';
import { TabsBar, TabsBarItem } from './src/core/components/Tabs';
import Textarea from './src/core/components/Textarea';
import Translation, {
  TranslationItem,
  TranslationItemLang,
} from './src/core/components/Translation';
import theme from './src/core/styles/theme';
import { isMobile, isWebapp } from './src/core/sysext/CMS/actions';
import extendData from './src/core/sysext/CMS/components/Data';
import { isLogout, load } from './src/core/sysext/CMS/containers/User/actions';
import { mobilecheck } from './src/core/utils/functions';
import getControlStyle from './src/core/utils/getControlStyle';
import i18n from './src/i18n';
import reducers from './src/reducers';

let isMobileScreen = false;
let isWebappScreen = false;
if (typeof document !== `undefined` && typeof window !== `undefined`) {
  isMobileScreen = mobilecheck();
  const viewport = document.querySelector('meta[name=viewport]');
  if (viewport) {
    viewport.setAttribute(
      'content',
      'width=device-width, height=device-height, initial-scale=1, initial-scale=1',
    );
  }

  if (isMobileScreen) {
    document.documentElement.classList.remove('desktop');
    document.documentElement.classList.add('mobile');
  } else {
    document.documentElement.classList.add('desktop');
    document.documentElement.classList.remove('mobile');
  }

  isWebappScreen = !!window.navigator.standalone;
  if (isWebappScreen) {
    document.documentElement.classList.add('webapp');
  } else {
    document.documentElement.classList.remove('webapp');
  }
}

const initializeStore = store => {
  store.dispatch(isMobile(isMobileScreen));
  store.dispatch(isWebapp(isWebappScreen));

  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        store.dispatch(load(user));
      } else {
        store.dispatch(isLogout());
      }
    });
  }
};

const WrapRootElement = ({ element }) => {
  // process.env.BROWSER && window.State ? window.State.redux : {};

  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(registrations => registrations.forEach(r => r.unregister()))
      .catch(error => {
        console.error(error);
      });
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <InneditProvider>
      <ThemeProvider
        theme={{
          ...theme,
          mobile: isMobileScreen,
          webapp: isWebappScreen,
        }}
      >
        <DndProvider backend={isMobileScreen ? TouchBackend : HTML5Backend}>
          <TranslationI18N i18n={i18n}>
            {t => (
              <FormidableProvider
                extendData={extendData}
                extraReducers={reducers}
                getControlStyle={getControlStyle}
                initializeStore={initializeStore}
                sc={{
                  button: Button,
                  condition: FieldWrapper,
                  fieldMessage: FieldMessage,
                  fieldTemplate: FieldTemplate,
                  fieldWrapper: FieldWrapper,
                  group: Group,
                  groupBody: GroupBody,
                  groupDescription: GroupDescription,
                  groupTitle: GroupTitle,
                  // iconAdd: IconAdd,
                  // iconBack: IconBack,
                  // iconNext: IconNext,
                  // iconRemove: IconDelete,
                  // iconStep: IconStep,
                  // iconSuccess: IconSuccess,
                  input: Input,
                  inputGroup: InputGroup,
                  // label: LabelSC,
                  select: Select,
                  tabsBar: TabsBar,
                  tabsBarItem: TabsBarItem,
                  textarea: Textarea,
                  translation: Translation,
                  translationItem: TranslationItem,
                  translationItemLang: TranslationItemLang,
                }}
                t={t}
                theme={{
                  ...theme,
                  mobile: isMobileScreen,
                  webapp: isWebappScreen,
                }}
              >
                {element}
              </FormidableProvider>
            )}
          </TranslationI18N>
        </DndProvider>
      </ThemeProvider>
    </InneditProvider>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = WrapRootElement;
