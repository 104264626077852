import { Group } from 'dataformjs';
import React, { FC, ReactNode, SyntheticEvent } from 'react';

import Button from '../../../../components/Button';

const HOCGroup: FC<{
  addText?: string;
  addOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  customInfos?: ReactNode;
  title?: string;
}> = ({ addOnClick, addText, children, customInfos, title }) => (
  <Group
    customInfos={
      customInfos ??
      (addOnClick && addText && (
        <Button
          className="absolute right-0 min-h-0"
          onClick={addOnClick}
          status="link"
        >
          {addText}
        </Button>
      ))
    }
    title={title}
  >
    {children}
  </Group>
);

export default HOCGroup;
