import {
  BoutiqueData,
  CommandeProduitType,
  FirebaseFirestore,
  ProduitData,
} from '@innedit/innedit';
import { change, FieldArrayFieldsProps, useDispatch } from 'dataformjs';
import { diff } from 'deep-object-diff';
import { Link } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../../../../../../components/Button';
import ItemSC from './styles';

const Item: FC<{
  boutique: FirebaseFirestore.DocumentSnapshot;
  checked?: boolean;
  formName: string;
  index: number;
  isCapturable: boolean;
  marchands?: FirebaseFirestore.QueryDocumentSnapshot[];
  name: string;
  produits: FieldArrayFieldsProps<CommandeProduitType>;
  reversalTransferOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  selectedOnChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  showCheckbox: boolean;
  transferOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  boutique,
  checked,
  formName,
  index,
  isCapturable,
  marchands,
  name,
  produits,
  reversalTransferOnClick,
  selectedOnChange,
  showCheckbox,
  transferOnClick,
}) => {
  const dispatch = useDispatch();
  const [productMarchands, setProductMarchands] =
    useState<FirebaseFirestore.QueryDocumentSnapshot[]>();
  const produit = produits.get(index);

  useEffect(() => {
    let unsub: (() => void) | undefined;
    if (marchands && produit && produit.id) {
      const tmpMarchands: FirebaseFirestore.QueryDocumentSnapshot[] = [];
      if (!produit.transferredAt && !produit.canceledAt) {
        const produitModel = new ProduitData({
          boutique,
        });
        unsub = produitModel.watchById(produit.id, snapshot => {
          if (snapshot.get('marchands')) {
            Object.keys(snapshot.get('marchands')).forEach(key => {
              if (snapshot.get('marchands')[key]) {
                const findMarchand = marchands.find(m => m.id === key);
                if (findMarchand) {
                  tmpMarchands.push(findMarchand);
                }
              }
            });
            setProductMarchands(tmpMarchands);
            const newMarchands: {
              [key: string]: { amount: number; destination?: string };
            } = {};
            tmpMarchands.forEach(tmp => {
              if (produit.price) {
                newMarchands[tmp.id] = {
                  amount:
                    ((produit.qty || 1) * produit.price) / tmpMarchands.length,
                };

                if (tmp.get('stripeAccountId')) {
                  newMarchands[tmp.id].destination = tmp.get('stripeAccountId');
                }
              }
            });

            if (
              !produit.marchands ||
              Object.keys(diff(produit.marchands, newMarchands)).length > 0
            ) {
              dispatch(
                change(formName, name, {
                  ...produit,
                  marchands: newMarchands,
                }),
              );
            }
          }
        });
      } else if (produit.marchands) {
        Object.keys(produit.marchands).forEach(key => {
          if (produit.marchands && produit.marchands[key]) {
            const findMarchand = marchands.find(m => m.id === key);
            if (findMarchand) {
              tmpMarchands.push(findMarchand);
            }
          }
        });

        setProductMarchands(tmpMarchands);
      }
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, dispatch, formName, marchands, name, produit]);

  return (
    <ItemSC className="produit">
      {isCapturable && showCheckbox && (
        <input
          checked={checked}
          data-index={index}
          disabled={Boolean(produit.canceledAt || produit.refundedAt)}
          onChange={selectedOnChange}
          type="checkbox"
        />
      )}

      {produit.thumbnail && (
        <img
          alt={produit.name}
          src={`${BoutiqueData.getDomainImagesURL(boutique)}/h_120,w_120/${
            produit.thumbnail
          }`}
        />
      )}

      {!produit.thumbnail && produit.photos && produit.photos.length > 0 && (
        <img
          alt={produit.name}
          src={`${BoutiqueData.getDomainImagesURL(boutique)}/h_120,w_120/${
            produit.photos[0]
          }`}
        />
      )}

      <div className="infos">
        <div className="name">
          {produit.id ? (
            <Link
              style={{
                textDecoration:
                  (produit.canceledAt || produit.refundedAt) && 'line-through',
              }}
              to={`/boutique/produits/update/${produit.id}/`}
            >
              {produit.name}
            </Link>
          ) : (
            <strong
              style={{
                textDecoration:
                  (produit.canceledAt || produit.refundedAt) && 'line-through',
              }}
            >
              {produit.name}
            </strong>
          )}
        </div>
        {produit.variantLibelle && <span>{produit.variantLibelle}</span>}

        {productMarchands && (
          <ul>
            {productMarchands.map(m => (
              <li key={m.id}>
                <strong>{m.get('libelle')}</strong>
                {m.get('stripeAccountId') && (
                  <>
                    <span className="account-connected">Compte connecté</span>
                    {produit.confirmedAt && !produit.transferredAt && (
                      <Button
                        data-index={index}
                        onClick={transferOnClick}
                        size="sm"
                        text="Transférer le paiement"
                      />
                    )}
                    {produit.transferredAt &&
                      !produit.reversedAt &&
                      produit.marchands && (
                        <span className="account-connected--amount-aashed">
                          ↦ {produit.marchands[m.id].amountAashed}€
                        </span>
                      )}
                    {produit.refundedAt && !produit.reversedAt && (
                      <Button
                        data-index={index}
                        onClick={reversalTransferOnClick}
                        size="sm"
                        text="Annuler le transfert"
                      />
                    )}
                    {produit.reversedAt && (
                      <span className="account-connected--amount-aashed">
                        ↦ Le transfert a été annulé
                      </span>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="prix">
        <p
          style={{
            textDecoration:
              (produit.canceledAt || produit.refundedAt) && 'line-through',
          }}
        >
          {produit.price ? (
            <strong>{`${(produit.qty || 1) * produit.price} €`}</strong>
          ) : (
            <div>Aucun prix</div>
          )}
          {undefined !== produit.qty && 1 < produit.qty && (
            <span>{` (${produit.qty} x ${produit.price}€)`}</span>
          )}
        </p>
        {produit.canceledAt && <div style={{ color: 'red' }}>Annulé</div>}
        {produit.refundedAt && <div style={{ color: 'red' }}>Remboursé</div>}
      </div>
    </ItemSC>
  );
};

export default Item;
