import { DataProps } from 'dataformjs';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

// import PageSelect from '../containers/Page/components/Data/Select';
import UrlInput from '../Url/components/Data/Input';
import UrlLibelle from '../Url/components/Data/Libelle';
import UrlPathnames from '../Url/components/Data/Pathnames';

const Data: FC<
  DataProps & {
    boutique: firebase.default.firestore.DocumentSnapshot;
    field?: string;
    formName: string;
    type: string;
    urlType?: 'page' | 'post' | 'produit';
  }
> = props => {
  const { field, name, params, type, urlType } = props;

  switch (type) {
    case 'url/libelle':
      if (!name) {
        return (
          <div>url/libelle : erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!urlType) {
        return (
          <div>url/libelle : erreur de paramètre : urlType est obligatoire</div>
        );
      }

      return (
        <UrlLibelle
          {...props}
          docId={params?.docId}
          name={name}
          urlType={urlType}
        />
      );

    case 'url/input':
      if (!params || !params.docId) {
        return (
          <div>
            commande/produits : erreur de paramètre : params.docId est
            obligatoire
          </div>
        );
      }

      if (!name) {
        return (
          <div>url/input : erreur de paramètre : name est obligatoire</div>
        );
      }

      if (!field) {
        return (
          <div>url/input : erreur de paramètre : field est obligatoire</div>
        );
      }

      if (!urlType) {
        return (
          <div>url/libelle : erreur de paramètre : urlType est obligatoire</div>
        );
      }

      return (
        <UrlInput
          {...props}
          docId={params.docId}
          field={field}
          name={name}
          urlType={urlType}
        />
      );

    case 'url/pathnames':
      if (!params || !params.docId) {
        return (
          <div>
            url/pathnames : erreur de paramètre : params.docId est obligatoire
          </div>
        );
      }

      return <UrlPathnames {...props} docId={params.docId} />;

    default:
  }

  return <div>Ce type de composant n&apos;existe pas : {type}</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Data;
