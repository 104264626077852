import { UrlData } from '@innedit/innedit';
import classnames from 'classnames';
import {
  change,
  DataField,
  DataFieldInputProps,
  useDispatch,
  useSelector,
} from 'dataformjs';
import React, { FC, useState } from 'react';
import slug from 'slug';

import Button from '../../../../../components/Button';
import IconSave from '../../../../../icons/Save';

export interface LibelleProps extends DataFieldInputProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  docId?: string;
  formName: string;
  name: string;
  pathname?: string;
  parentId?: string;
  urlType: 'page' | 'post' | 'produit';
}

const Libelle: FC<Omit<LibelleProps, 'componentType'>> = ({
  boutique,
  docId,
  formName,
  name,
  urlType,
  ...props
}) => {
  const [updateAvailable, setUpdateAvailable] = useState<boolean>();
  const dispatch = useDispatch();
  const values = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );
  const { parent: parentId, pathname } = values;
  const libelle = values[name];
  const urlData = new UrlData({ boutique });

  const handleBlur = ({ value }: { value?: string }) => {
    if (
      value &&
      (!pathname ||
        ![`${urlType}/${slug(value)}`, slug(value)].includes(pathname))
    ) {
      if (pathname) {
        setUpdateAvailable(true);
      } else if (docId) {
        urlData
          .clear(value, urlType, docId, { parentId })
          .then(url => {
            console.info('url', url.get('pathname'));

            return dispatch(change(formName, 'pathname', url.get('pathname')));
          })
          .catch(error => {
            throw new Error(`Url.clear : ${error.message}`);
          });
      }
    }
  };

  const handleUpdatePathnameOnCick = () => {
    setUpdateAvailable(false);

    if (docId) {
      urlData
        .clear(libelle, urlType, docId, { parentId })
        .then(url => console.info('url', url))
        .catch(error => {
          throw new Error(`Url.clear : ${error.message}`);
        });
    }
  };

  return (
    <DataField
      {...props}
      componentType="input"
      customAction={
        updateAvailable ? (
          <Button
            iconLeft={IconSave}
            onClick={handleUpdatePathnameOnCick}
            status="field-action"
          />
        ) : undefined
      }
      fieldProps={{
        className: classnames('w-full flex-1', {
          'border-r-0 rounded-r-none': updateAvailable,
        }),
      }}
      handleOnBlur={handleBlur}
      name={name}
    />
  );
};

export default Libelle;
