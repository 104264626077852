import { FirebaseFirestore } from '@innedit/innedit';
import { DataArrayProps, WrappedFieldArrayProps } from 'dataformjs';
import React, { FC } from 'react';

import Medias from '../../../../CMS/components/Medias';

const DataMediasRender: FC<
  WrappedFieldArrayProps &
    DataArrayProps & {
      boutique: FirebaseFirestore.DocumentSnapshot;
    }
> = ({ addButtonLabel, boutique, fields, label }) => {
  const handleAddItems = (
    medias: FirebaseFirestore.DocumentSnapshot[],
  ): void => {
    medias.forEach(media => {
      if (media.exists) {
        fields.push(media.id);
      }
    });
  };

  const handleChangePosition = (oldIndex: number, newIndex: number): void => {
    const mediaId = fields.get(oldIndex);
    if (newIndex !== oldIndex) {
      if (newIndex < oldIndex) {
        fields.insert(newIndex, mediaId);
        fields.remove(oldIndex + 1);
      } else {
        // la nouvelle position est après
        fields.remove(oldIndex);
        fields.insert(newIndex, mediaId);
      }
    }
  };

  const handleRemoveItem = (index: number): void => {
    fields.remove(index);
  };

  const items: string[] = fields.length > 0 ? fields.getAll() : [];

  return (
    <Medias
      addItems={handleAddItems}
      addText={addButtonLabel ?? 'Ajouter un média'}
      boutique={boutique}
      changePosition={handleChangePosition}
      items={items}
      removeItem={handleRemoveItem}
      title={label ?? 'Médias'}
    />
  );
};

export default DataMediasRender;
