import { BoutiqueType, FirebaseFirestore } from '@innedit/innedit';
import { DataProps } from 'dataformjs';
import React, { FC } from 'react';

import BoutiqueLanguage from '../../CMS/containers/Boutique/components/Data/Language';
import BoutiqueList from '../../CMS/containers/Boutique/components/Data/List';
import BoutiquePricing from '../../CMS/containers/Boutique/components/Data/Pricing';
import BoutiqueSelect from '../../CMS/containers/Boutique/components/Data/Select';
// import CommandeNumero from '../containers/Commande/components/Data/Numero';
// import CommandePayments from '../containers/Commande/components/Data/Payments';
import CommandeProduits from '../containers/Commande/components/Data/Produits';
import CommandeResume from '../containers/Commande/components/Data/Resume';
import CommandeSelect from '../containers/Commande/components/Data/Select';
// import CommandeTabsClient from '../containers/Commande/components/Data/Tabs/Client';
// import CommandeTabsOptions from '../containers/Commande/components/Data/Tabs/Options';
// import CommandeAcompte from '../containers/Commande/components/Data/Validation/Acompte';
// import CommandeValidationAutomatic from '../containers/Commande/components/Data/Validation/Automatic';
// import ContactFiltre from '../containers/Contact/components/Data/Filtre';
import ContactMessages from '../containers/Contact/components/Data/Messages';
import ContactSelect from '../containers/Contact/components/Data/Select';
import MarchandKYC from '../containers/Marchand/components/Data/KYC';
import MarchandMultiSelect from '../containers/Marchand/components/Data/MultiSelect';
// import ProduitAlgolia from '../containers/Produit/components/Data/Algolia';
import ProduitCaracteristiques from '../containers/Produit/components/Data/Caracteristiques';
import ProduitDimensions from '../containers/Produit/components/Data/Dimensions';
import ProduitEstimationLivraison from '../containers/Produit/components/Data/EstimationLivraison';
import ProduitInfos from '../containers/Produit/components/Data/Infos';
import ProduitInventaire from '../containers/Produit/components/Data/Inventaire';
import ProduitSku from '../containers/Produit/components/Data/Sku';
import SuggestionProduits from '../containers/Suggestion/components/Data/Produits';
import SuggestionSelect from '../containers/Suggestion/components/Data/Select';
import Medias from './Data/Medias';
import Photos from './Data/Photos';
import POS from './Data/POS';

const Data: FC<
  DataProps & {
    boutique?: FirebaseFirestore.DocumentSnapshot<BoutiqueType>;
    collectionName?: string;
    formName: string;
    type: string;
  }
> = props => {
  const { boutique, datas, formValues, name, params, type } = props;

  switch (type) {
    case 'commande/produits':
      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }
      if (!formValues) {
        return (
          <div>{type}: erreur de paramètre : formValues est obligatoire</div>
        );
      }
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }
      if (!params || !params.docId) {
        return (
          <div>{type}: erreur de paramètre : params.docId est obligatoire</div>
        );
      }

      return (
        <CommandeProduits
          {...props}
          boutique={boutique}
          docId={params.docId}
          formValues={formValues}
          name={name}
        />
      );

    // case 'commande/numero':
    //   return <CommandeNumero {...props} />;
    //
    case 'commande/resume':
      return <CommandeResume {...props} />;

    case 'commande/select':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <CommandeSelect {...props} name={name} />;
    // case 'commande/paiements':
    //   return <CommandePayments {...props} />;
    //
    // case 'commande/acompte':
    //   return <CommandeAcompte {...props} />;
    //
    // case 'commande/validation/automatic':
    //   return <CommandeValidationAutomatic {...props} />;
    //
    // case 'commande/tabs/options':
    //   return <CommandeTabsOptions {...props} />;
    //
    case 'contact/messages':
      if (!params || !params.docId) {
        return (
          <div>{type}: erreur de paramètre : params.docId est obligatoire</div>
        );
      }

      return <ContactMessages {...props} docId={params.docId} />;

    case 'contact/select':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <ContactSelect {...props} componentType="contact/select" name={name} />
      );

    case 'suggestion/produits':
      return <SuggestionProduits {...props} />;

    case 'suggestion/select':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }

      return <SuggestionSelect {...props} boutique={boutique} name={name} />;
    // case 'contact/filtre':
    //   return <ContactFiltre {...props} />;
    //

    case 'pricing':
      if (!datas) {
        return <div>{type}: erreur de paramètre : datas est obligatoire</div>;
      }

      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <BoutiquePricing
          {...props}
          boutique={boutique}
          datas={datas}
          name={name}
        />
      );

    case 'language':
      if (!datas) {
        return <div>{type}: erreur de paramètre : datas est obligatoire</div>;
      }

      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }

      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <BoutiqueLanguage
          {...props}
          boutique={boutique}
          datas={datas}
          name={name}
        />
      );

    case 'list':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <BoutiqueList {...props} name={name} />;

    case 'marchand/kyc':
      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }
      if (!params || !params.docId) {
        return (
          <div>{type}: erreur de paramètre : params.docId est obligatoire</div>
        );
      }

      return (
        <MarchandKYC {...props} boutique={boutique} docId={params.docId} />
      );

    case 'marchand/multiselect':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <MarchandMultiSelect {...props} name={name} />;

    case 'medias': {
      return <Medias {...props} />;
    }

    case 'photos': {
      return <Photos {...props} />;
    }

    // case 'produit/list':
    //   return <ProduitList {...props} />;

    // case 'produit/algolia':
    //   return <ProduitAlgolia {...props} />;
    //

    case 'pointofsale': {
      return <POS {...props} />;
    }

    case 'produit/caracteristiques':
      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <ProduitCaracteristiques {...props} boutique={boutique} name={name} />
      );

    case 'produit/dimensions':
      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <ProduitDimensions {...props} boutique={boutique} name={name} />;

    case 'produit/infos':
      return <ProduitInfos {...props} />;

    case 'produit/inventaire':
      return <ProduitInventaire {...props} />;

    case 'produit/estimationLivraison':
      if (!boutique) {
        return (
          <div>{type}: erreur de paramètre : boutique est obligatoire</div>
        );
      }

      return <ProduitEstimationLivraison {...props} boutique={boutique} />;

    case 'produit/sku':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <ProduitSku {...props} componentType="produit/sku" name={name} />;

    case 'select':
      if (!name) {
        return <div>{type}: erreur de paramètre : name est obligatoire</div>;
      }

      return <BoutiqueSelect {...props} name={name} />;

    default:
  }

  return <div>{`boutique : ce type de composant n'existe pas :  ${type}`}</div>;
};

export default Data;
