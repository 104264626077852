import { FirebaseFirestore, MessageType } from '@innedit/innedit';
import moment from 'moment';
import React, { FC } from 'react';

import MessageProduit from './Produit';
import { BulleEl, DateEl, LigneEl, MessageEl } from './styles';

interface MessageProps {
  boutique?: FirebaseFirestore.DocumentSnapshot;
  doc: FirebaseFirestore.DocumentSnapshot<MessageType>;
}
const Message: FC<MessageProps> = ({ boutique, doc }) => {
  if (!doc.exists) {
    return null;
  }
  const data = doc.data();
  if (!data) {
    return null;
  }
  console.info('message data', data);
  const {
    cartProducts,
    createdAt,
    priceProducts,
    reservedProducts,
    text,
    user,
  } = data;

  return (
    <MessageEl>
      <DateEl>{moment(createdAt).calendar()}</DateEl>
      {text && (
        <LigneEl isUser={user}>
          <BulleEl isUser={user}>
            <p>{text}</p>
          </BulleEl>
        </LigneEl>
      )}

      {priceProducts &&
        priceProducts.map(product => (
          <MessageProduit
            key={product}
            boutique={boutique}
            id={product}
            requestType="price"
            user={user}
          />
        ))}
      {cartProducts &&
        cartProducts.map(product => (
          <MessageProduit
            key={product}
            boutique={boutique}
            id={product}
            requestType="reservation"
          />
        ))}
      {reservedProducts &&
        reservedProducts.map(product => (
          <MessageProduit
            key={product}
            boutique={boutique}
            id={product}
            requestType="reservation"
          />
        ))}
    </MessageEl>
  );
};

export default Message;
