import {
  BoutiqueData,
  FirebaseFirestore,
  MediaData,
  MediaType,
} from '@innedit/innedit';
import classnames from 'classnames';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import nextPhoto from '../../../../images/next-photo.svg';

const ItemTypes = {
  MEDIA: 'media',
};

const Media: FC<{
  boutique?: FirebaseFirestore.DocumentSnapshot;
  index: number;
  isDragging: boolean;
  mediaId: string;
  removeOnClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ boutique, index, isDragging, mediaId, removeOnClick }) => {
  const [media, setMedia] =
    useState<FirebaseFirestore.DocumentSnapshot<MediaType>>();

  useEffect(() => {
    const mediaData = new MediaData({ boutique });
    const unsub = mediaData.watchById(mediaId, document => setMedia(document));

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, mediaId]);

  let newMedia;
  try {
    if (media) {
      newMedia = `${BoutiqueData.getDomainImagesURL(boutique)}/${media.get(
        'fullPath',
      )}`;
      newMedia += 0 === index ? '?w=640&h=640&c=max' : '?w=320&h=320&c=max';
    } else {
      newMedia = nextPhoto;
    }
  } catch (e) {
    newMedia = nextPhoto;
  }

  const handleClipboardOnClick = () => {
    if (navigator && navigator.clipboard && media) {
      navigator.clipboard.writeText(
        `${BoutiqueData.getDomainImagesURL(boutique)}/${media.get('fullPath')}`,
      );
    }
  };

  return (
    <div
      className={classnames(
        {
          isDragging,
        },
        'relative',
      )}
    >
      <figure className="aspect-ratio aspect-ratio--square">
        <img
          alt="chargement"
          className="aspect-ratio__content"
          src={newMedia}
        />
      </figure>
      <div className="hover-overlay">
        {media && (
          <div className="text-sm text-white-50 text-center">
            <div>{media.get('type')}</div>
            {media.get('width') && media.get('height') && (
              <div>{`${media.get('width')} x ${media?.get('height')} px`}</div>
            )}
            {media.get('size') && (
              <div>{`${Math.round(media.get('size') / 10000) / 100} Mo`}</div>
            )}
          </div>
        )}
        <div className="flex justify-around">
          <button
            className="clipboard"
            data-index={index}
            onClick={handleClipboardOnClick}
            type="button"
          >
            <span>Copier</span>
          </button>
          <button
            className="supprimer"
            data-index={index}
            onClick={removeOnClick}
            type="button"
          >
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    </div>
  );
};

interface DragMedia {
  index: number;
  id: string;
  type: string;
}

const MediaPosition: FC<{
  boutique?: FirebaseFirestore.DocumentSnapshot;
  changePosition: (oldIndex: number, newIndex: number) => void;
  index: number;
  mediaId: string;
  removeItem: (index: number) => void;
}> = ({ boutique, changePosition, removeItem, index, mediaId }) => {
  const ref = useRef<HTMLLIElement>(null);

  const handleRemoveOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const i = e.currentTarget.getAttribute('data-index');

    if (i) {
      if (window.confirm('Voulez-vous vraiment supprimer ce média ?')) {
        removeItem(parseInt(i, 10));
      }
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MEDIA,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragMedia) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      changePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, id: mediaId, type: ItemTypes.MEDIA },
  });

  drag(drop(ref));

  return (
    <li ref={ref} className={isOver ? 'isOver' : ''}>
      <Media
        boutique={boutique}
        index={index}
        isDragging={isDragging}
        mediaId={mediaId}
        removeOnClick={handleRemoveOnClick}
      />
    </li>
  );
};

export default MediaPosition;
