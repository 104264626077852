import {
  CommandeData,
  FirebaseFirestore,
  PanierShippingInfoType,
  ShippingRuleType,
  ShippingValueType,
} from '@innedit/innedit';
import React, { FC } from 'react';

const TransportRule: FC<{
  rule: FirebaseFirestore.QueryDocumentSnapshot<ShippingRuleType>;
  valeurs: FirebaseFirestore.QueryDocumentSnapshot<ShippingValueType>[];
  shippingInfo: PanierShippingInfoType;
}> = ({ rule, valeurs, shippingInfo }) => {
  let bestExpress: FirebaseFirestore.QueryDocumentSnapshot | undefined;
  let best: Partial<ShippingValueType> = {
    coefVolume: rule.get('coefVolume'),
    coefWeight: rule.get('coefWeight'),
    pec: rule.get('pec'),
    weight: undefined,
  };

  const volume = shippingInfo.totalVolume || 0;
  const vw = shippingInfo.totalVW || shippingInfo.totalWeight || 0;
  const weight = shippingInfo.totalWeight || 0;

  valeurs.forEach(valeur => {
    if (!valeur.get('express') || shippingInfo.express) {
      if (valeur.get('express')) {
        // il faut prendre le poids unitaire par colis
        const expressWeight =
          shippingInfo.nbWeight > 0
            ? shippingInfo.totalWeight / shippingInfo.nbWeight
            : shippingInfo.totalVW / shippingInfo.nbVolume;
        // il n'y a que le poids qui est pris en compte pour les livraisons express
        if (expressWeight && Number(valeur.get('weight') >= expressWeight)) {
          if (undefined === bestExpress) {
            bestExpress = valeur;
          } else {
            let bestDiff = 100000;
            if (bestExpress.get('weight')) {
              // le poids existe
              bestDiff = Number(bestExpress.get('weight')) - expressWeight;
            }
            let valeurDiff = 100000;
            if (valeur.get('weight')) {
              // le poids existe
              valeurDiff = Number(valeur.get('weight')) - expressWeight;
            }

            if (bestDiff > valeurDiff) {
              bestExpress = valeur;
            } else if (bestDiff === valeurDiff) {
              // il faut calculer la moins chere
              const bestPrice = Number(bestExpress.get('pec') || 0);

              const valeurPrice = Number(valeur.get('pec') || 0);

              if (valeurPrice < bestPrice) {
                bestExpress = valeur;
              }
            }
          }
        }
      } else if (
        vw &&
        (!valeur.get('weight') || Number(valeur.get('weight') >= vw))
      ) {
        // Cette régle est applicable
        if (undefined === best) {
          best = valeur.data();
        } else {
          let bestDiff = 100000;
          if (best.weight) {
            // le poids existe
            bestDiff = Number(best.weight) - weight;
          }
          let valeurDiff = 100000;
          if (valeur.get('weight')) {
            // le poids existe
            valeurDiff = Number(valeur.get('weight')) - weight;
          }
          if (bestDiff > valeurDiff) {
            best = valeur.data();
          } else if (bestDiff === valeurDiff) {
            // il faut calculer la moins chere
            const bestPrice =
              volume * Number(best.coefVolume || 0) +
              weight * Number(best.coefWeight || 0) +
              Number(best.pec || 0);

            const valeurPrice =
              volume * Number(valeur.get('coefVolume') || 0) +
              weight * Number(valeur.get('coefWeight') || 0) +
              Number(valeur.get('pec') || 0);

            if (valeurPrice < bestPrice) {
              best = valeur.data();
            }
          }
        }
      }
    }
  });

  return (
    <div className="w-full grid grid-cols-3 gap-6">
      <span className="col-span-2">{rule.get('libelle')}</span>
      <div className="flex space-x-6">
        {best && (
          <span className="text-right">
            {`${CommandeData.calcShippingCostWithShippingValue(
              best,
              shippingInfo,
            )}€`}
          </span>
        )}

        {bestExpress && (
          <span className="text-right">
            {`${CommandeData.calcShippingCostWithShippingValue(
              bestExpress.data(),
              shippingInfo,
            )}€`}
          </span>
        )}
        {!best && !bestExpress && <span>Aucune règle</span>}
      </div>
    </div>
  );
};
export default TransportRule;
