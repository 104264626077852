import { FirebaseFirestore, ProduitData, ProduitType } from '@innedit/innedit';
import { DataProps, useSelector } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';

import Item from './Item';

interface DataProduitsProps extends DataProps {
  boutique?: FirebaseFirestore.DocumentSnapshot;
  formName: string;
}

const DataProduits: FC<DataProduitsProps> = ({ boutique, formName }) => {
  const [docs, setDocs] = useState<ProduitType[]>();

  const { libelle, query } = useSelector(
    (state: any) => state.form[formName].values,
  );
  useEffect(() => {
    const handle = () => {
      const produitData = new ProduitData({ boutique });

      produitData
        .search(query || libelle)
        .then(result => {
          console.info('result', result);

          return setDocs(
            result.hits.map(hit => ({ ...hit, id: hit.objectID })),
          );
        })
        .catch(error => {
          console.error(error.message);
        });
    };

    if (query || libelle) {
      handle();
    }
  }, [boutique, query, libelle]);

  return (
    <div>
      Produits
      {docs && docs.length > 0 && (
        <ul className="grid md:grid-cols-4 lg:grid-cols-8 gap-6">
          {docs.map(doc => (
            <Item key={doc.id} data={doc} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default DataProduits;
