import { FirebaseFirestore, MediaData } from '@innedit/innedit';
import compact from 'lodash/compact';
import moment from 'moment';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Button from '../../../../components/Button';
import Item from '../../../Boutique/containers/Media/components/List/Item';
import ActionBar from '../ActionBar';
import CMSList from '../View/List';

const MediasList: FC<{
  addItems: (medias: FirebaseFirestore.DocumentSnapshot[]) => void;
  boutique?: FirebaseFirestore.DocumentSnapshot;
}> = ({ addItems, boutique }) => {
  const [ids, setIds] = useState<string[]>([]);
  const [medias, setMedias] =
    useState<FirebaseFirestore.QueryDocumentSnapshot[]>();
  const [nbParLigne, setNbParLigne] = useState<number>(5);

  useEffect(() => {
    let unsub: () => void;

    if (boutique) {
      unsub = MediaData.watchNotUse(
        querySnapshot => {
          setMedias(querySnapshot.docs);
        },
        {
          boutique,
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique]);

  const handleOnChangeNbParLigne = (e: SyntheticEvent<HTMLInputElement>) => {
    setNbParLigne(parseInt(e.currentTarget.value, 10));
  };

  if (!medias || 0 === medias.length) {
    return null;
  }

  const handleOnClick = (e: SyntheticEvent<HTMLLIElement>) => {
    const id = e.currentTarget.getAttribute('data-id');
    if (id) {
      const newIds = [...ids];
      console.info('id', id);
      const index = ids.findIndex(tmp => 0 === tmp.localeCompare(id));
      if (index >= 0) {
        newIds.splice(index, 1);
      } else {
        newIds.push(id);
      }

      setIds(newIds);
    }
  };

  const handleSelectMedias = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (ids && ids.length > 0) {
      const promises: Promise<void>[] = [];
      const newItems = compact(
        ids.map(id => {
          const media = medias.find(m => m.id === id);

          if (media) {
            promises.push(
              media.ref.update({
                updatedAt: moment().toISOString(),
              }),
            );
          }

          return media;
        }),
      );

      Promise.all(promises)
        .then(() => addItems(newItems))
        .catch(error => {
          console.error(error.message);
        });
    }
  };

  return (
    <>
      <ActionBar className="action-bar is-clearfix">
        <ul className="border-left navbar-right">
          <li>
            <Button
              onClick={handleSelectMedias}
              text="Selectionner ces photos"
            />
          </li>
          <li className="navbar-item nbByRow">
            <input
              onChange={handleOnChangeNbParLigne}
              type="number"
              value={nbParLigne}
            />
          </li>
        </ul>
      </ActionBar>
      <CMSList title="Médias">
        <ol>
          {medias.map(media => (
            <Item
              key={media.id}
              boutique={boutique}
              doc={media}
              isSelected={ids.includes(media.id)}
              nbParLigne={nbParLigne}
              onClick={handleOnClick}
            />
          ))}
        </ol>
      </CMSList>
    </>
  );
};

export default MediasList;
