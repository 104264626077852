import {
  CommandeData,
  FeatureType,
  FirebaseFirestore,
  PanierShippingInfoType,
  ProduitType,
} from '@innedit/innedit';
import { DataProps, useSelector } from 'dataformjs';
import React, { useEffect, useState, VoidFunctionComponent } from 'react';

import TransportRule from './TransportRule';

export interface LivraisonsProps extends DataProps {
  boutique: FirebaseFirestore.DocumentSnapshot;
  formName: string;
}

const EstimationLivraison: VoidFunctionComponent<LivraisonsProps> = ({
  boutique,
  formName,
}) => {
  const [transports, setTransports] = useState<any[]>([]);
  const values: ProduitType = useSelector(
    (state: any) => state.form[formName].values,
  ) as ProduitType;

  useEffect(() => {
    let unsub: () => void;
    if (boutique) {
      unsub = (
        boutique.ref.collection(
          'livraisons',
        ) as FirebaseFirestore.CollectionReference<FeatureType>
      )
        .where('deleted', '==', false)
        .orderBy('datetime', 'desc')
        .onSnapshot(async snapshot => {
          const promises = snapshot.docs.map(
            doc =>
              new Promise((resolve, reject) =>
                doc.ref
                  .collection('valeurs')
                  .where('deleted', '==', false)
                  .get()
                  .then(snap =>
                    resolve({
                      doc,
                      valeurs: snap.docs,
                    }),
                  )
                  .catch(reject),
              ),
          );

          const result = await Promise.all(promises);
          setTransports(result);
        });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique]);

  let shippingInfo: PanierShippingInfoType;
  const commandeData = new CommandeData({ boutique });
  try {
    shippingInfo = commandeData.calcShippingInfo({
      ...values,
      id: values.id,
      price: values.price || 0,
      qty: 1,
    });
  } catch (error) {
    return <div>{error.message}</div>;
  }

  if (!shippingInfo.volume && !shippingInfo.weight) {
    return <div>Impossible de calculer la livraison</div>;
  }

  return (
    <>
      <dl className="grid grid-cols-2">
        {shippingInfo.totalVolume > 0 && (
          <>
            <dt>Volume</dt>
            <dd>
              {`${shippingInfo.nbVolume} x ${
                Math.round(shippingInfo.volume * 1000) / 1000
              } = ${Math.round(shippingInfo.totalVolume * 1000) / 1000} m3`}
            </dd>
          </>
        )}

        {shippingInfo.totalVW > 0 && (
          <>
            <dt>Poids volumétrique</dt>
            <dd>{`${shippingInfo.nbVolume} x ${
              Math.round(shippingInfo.volumetricWeight * 100) / 100
            } = ${Math.round(shippingInfo.totalVW * 100) / 100} kg`}</dd>
          </>
        )}

        {shippingInfo.totalWeight > 0 && (
          <>
            <dt>Poids</dt>
            <dd>{`${shippingInfo.nbWeight} x ${shippingInfo.weight} = ${shippingInfo.totalWeight} kg`}</dd>
          </>
        )}
        {!shippingInfo.express && (
          <>
            <dt>Livraison express</dt>
            <dd>Impossible</dd>
          </>
        )}
        {shippingInfo.dimensionMax > 0 && (
          <>
            <dt>Dévelopé</dt>
            <dd>{`${shippingInfo.dimensionMax} cm`}</dd>
          </>
        )}
      </dl>

      {shippingInfo.freeShipping ? (
        <div>La livraison est offerte</div>
      ) : (
        <div>
          {transports.map(({ doc: rule, valeurs }) => (
            <TransportRule
              key={rule.id}
              rule={rule}
              shippingInfo={shippingInfo}
              valeurs={valeurs}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default EstimationLivraison;
