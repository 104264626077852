import {
  BoutiqueType,
  FirebaseFirestore,
  SuggestionData,
  SuggestionType,
  WhereProps,
} from '@innedit/innedit';
import { DataField, DataFieldProps } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';

interface SelectProps extends Omit<DataFieldProps, 'componentType'> {
  boutique: FirebaseFirestore.DocumentSnapshot<BoutiqueType>;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  wheres?: WhereProps;
}

const Select: FC<SelectProps> = ({
  boutique,
  className,
  formName,
  label,
  labelShow,
  name,
  orderDirection = 'asc',
  orderField = 'libelle',
  wheres,
}) => {
  const [options, setOptions] =
    useState<FirebaseFirestore.QueryDocumentSnapshot<SuggestionType>[]>();

  useEffect(() => {
    const feature = new SuggestionData({
      boutique,
      orderDirection,
      orderField,
    });
    const unsub: () => void = feature.watch(
      snapshot => {
        setOptions(snapshot.docs);
      },
      {
        wheres,
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, orderDirection, orderField, wheres]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      labelShow={labelShow}
      name={name}
      options={options.map(item => ({
        label: item.get('libelle'),
        value: item.id,
      }))}
    />
  );
};

export default Select;
