import { CommandeType, FirebaseFirestore } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import React, { FC } from 'react';

import StyledResume from '../../styles/Resume';

interface ResumeProps {
  boutique?: FirebaseFirestore.DocumentSnapshot;
  formName: string;
}
const Resume: FC<ResumeProps> = ({ formName }) => {
  const {
    amountAashed,
    amountAashedProducts,
    amountAashedDelivery,
    amountRefunded,
    deliveryCost,
    deliveryMethod,
    inneditPayInFees,
    isPaidToCarrier,
    stripePayInFees,
    paymentLastError,
    paymentLastErrorCode,
    paymentNextAction,
    paymentNextActionType,
    paymentStatus,
    paymentType,
    produits,
    subTotal,
  }: CommandeType = useSelector((state: any) => state.form[formName].values);

  if (produits) {
    let paymentStatusLabel: string;
    switch (paymentStatus) {
      case 'canceled':
        paymentStatusLabel = 'Annulée';
        break;

      default:
        paymentStatusLabel = paymentStatus;
    }

    const total =
      Number(subTotal) +
      ('carrier' === deliveryMethod ? Number(deliveryCost) : 0);

    return (
      <StyledResume>
        <div className="analytics">
          <div className="total">
            <span className="label">Total</span>
            {amountAashed ? (
              <span>{amountAashed}€</span>
            ) : (
              <span>{total}€</span>
            )}
          </div>
          <div className="infos">
            <h2>Détails</h2>
            <div className="datas">
              <div className="produits">
                {amountAashedProducts ? (
                  <span>{amountAashedProducts}€</span>
                ) : (
                  <span>{subTotal}€</span>
                )}
                <span className="label">Produits</span>
              </div>
              {'carrier' === deliveryMethod && (
                <div>
                  {amountAashedDelivery ? (
                    <span>{amountAashedDelivery}€</span>
                  ) : (
                    <span>{deliveryCost}€</span>
                  )}
                  <span className="label">
                    Livraison {!!isPaidToCarrier && '(au transporteur)'}
                  </span>
                </div>
              )}
            </div>
          </div>
          {paymentType && (
            <div className="paiement">
              <h2>Paiement</h2>
              <div className="datas">
                {'succeeded' !== paymentStatus && (
                  <div className="solde">
                    <span>{total} €</span>
                    <div className="label flex space-x-3">
                      <span>{paymentStatusLabel}</span>
                      {paymentLastError &&
                        ['payment_intent_authentication_failure'].includes(
                          paymentLastError,
                        ) && <span>échec de l&apos;authentification</span>}
                      {paymentLastError &&
                        !['payment_intent_authentication_failure'].includes(
                          paymentLastError,
                        ) && <span>{paymentLastError}</span>}
                      {paymentLastErrorCode && (
                        <span>{paymentLastErrorCode}</span>
                      )}

                      {paymentNextAction &&
                        paymentNextActionType &&
                        [
                          'three_d_secure_redirect',
                          'stripe_3ds2_fingerprint',
                        ].includes(paymentNextActionType) && (
                          <span>3D Secure</span>
                        )}
                      {paymentNextAction &&
                        paymentNextActionType &&
                        ![
                          'three_d_secure_redirect',
                          'stripe_3ds2_fingerprint',
                        ].includes(paymentNextActionType) && (
                          <span>{paymentNextActionType}</span>
                        )}

                      {paymentNextAction && !paymentNextActionType && (
                        <span>{paymentNextAction}</span>
                      )}
                    </div>
                  </div>
                )}
                {'succeeded' === paymentStatus &&
                  undefined !== amountAashed &&
                  0 === amountAashed && (
                    <div className="solde">
                      <span>{total} €</span>
                      <span className="label">Pré-paiement</span>
                    </div>
                  )}
                {undefined !== amountAashed && amountAashed > 0 && (
                  <div>
                    <span>{amountAashed} €</span>
                    <span className="label">Débit</span>
                  </div>
                )}
                {undefined !== amountAashed &&
                  undefined !== stripePayInFees &&
                  stripePayInFees > 0 && (
                    <div>
                      <span>
                        {Math.round(
                          (stripePayInFees + (inneditPayInFees || 0)) * 100,
                        ) / 100}{' '}
                        €
                      </span>
                      <span className="label">
                        Frais (
                        {Math.round(
                          ((stripePayInFees + (inneditPayInFees || 0)) /
                            amountAashed) *
                            10000,
                        ) / 100}
                        %)
                      </span>
                    </div>
                  )}

                {undefined !== amountRefunded && amountRefunded > 0 && (
                  <div className="refunded">
                    <span>{amountRefunded} €</span>
                    <span className="label">Remboursement</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div />
      </StyledResume>
    );
  }

  return null;
};

export default Resume;
